<template>
  <div>
    <a-modal :width="600" :visible="visible" :confirm-loading="loading" :title="isUpdate ? '修改管理权限' : '新建管理权限'"
      @ok="saveAuthor" @update:visible="updateVisible" :body-style="{ paddingBottom: '8px' }">
      <a-row>
        <a-col sm="24">
          <a-form :model="authorForm" :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
            :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }">
            <a-form-item label="管理员" name="manageId">
              <a-select v-model:value="form.manageId" :token-separators="[',']" showArrow @change="change"
                mode="multiple" placeholder="请选择管理员">
                <a-select-option v-for="(item, index) in options" :key="index" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="权限" name="authorBox">
              <a-checkbox-group v-model:value="form.authorBox" style="width: 100%">
                <a-row :gutter="16">
                  <a-col :span="8">
                    <a-checkbox value="reg-view">报名信息</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="sign-view">签到信息</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="sign-check">入场核验</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="luck-manage">抽奖管理</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="luck-check">抽奖核销</a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import * as activityUserRegApi from '@/api/activity/activityUserReg.js'
export default {
  props: {
    data: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isUpdate: false,
      // 提交状态
      loading: false,
      form: {},
      options: [] // 管理员列表
    }
  },
  mounted() {
    this.getUser(45)
  },
  methods: {
    getUser(activityId) {
      activityUserRegApi.all({ activityId: activityId }).then(res => {
        if (res.code === 0) {
          this.options = res.data.map(item => {
            const newItem = {}
            newItem.value = item.userId + ''
            newItem.label = item.name
            return newItem
          })
        }
      }).catch(() => {

      })
    },
    saveAuthor() {
      const manageId = this.arrToStr(this.form.manageId)
      const authorBox = this.arrToStr(this.form.authorBox)
      const data = { manageId, authorBox }
      this.$emit('done', data)
      this.updateVisible(false)
    },
    strToArr(str) {
      if (str) {
        return str.split(',')
      }
    },
    arrToStr(arr) {
      if (arr && arr.length > 0) {
        return arr.join(',')
      }
    },
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.isUpdate = true
        this.form.manageId = this.strToArr(this.data.manageId)
        this.form.authorBox = this.strToArr(this.data.authorBox)
      } else {
        this.form.authorBox = []
        this.form.manageId = []
        this.isUpdate = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
